import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import Badge from "../../components/_ui/Badge.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Scented Climbers for Pergolas`}</h1>
    <span className="text-metadata">Posted on 03/08</span>
    <Badge mdxType="Badge" />
    <img src="/img/outdoor-living/scented-climbers-for-pergolas/sweetpeas-GW.jpg" alt="A sweetpea plant, flowering with purple and voilet flowers" />
    <p><strong parentName="p">{`We’ve had a glimpse of spring so my confidence that summer is coming remains undimmed.`}</strong></p>
    <p>{`With this spirit of optimism in mind, picture yourself sitting in your garden one balmy summer’s evening, breathing intoxicating clouds of delicious floral perfume. This isn’t pure fantasy, my friends, this is the world of scented climbers.`}</p>
    <p>{`Pergolas provide the perfect architectural and visual backdrop for these fragrant beauties, which are happy to scramble up the wooden uprights and beyond, releasing their perfume as they go. Their fragrance is often particularly heady during the evening. Just when you’re sitting outside, enjoying the last rays of sunshine with a cool beverage. Rosé, anyone? Plant them up a lean-to pergola by the house and you’ll benefit from their aroma drifting through the open windows. Heavenly!`}</p>
    <img src="/img/outdoor-living/scented-climbers-for-pergolas/trachelospermum.jpg" alt="A trachelospermum climbing up a white brick wall" />
    <p>{`Here’s our selection of the best scented climbers. Most of them will reward you with stronger fragrance if you treat them to a sunny spot than if you plant them somewhere shady. Choose two or three that flower at different times, for fragrance all through the summer. Avoid planting varieties that flower at the same time - each plant’s scent is best enjoyed alone. Mix them all together and it could be overpowering.`}</p>
    <h4>{`Jasmine `}<em parentName="h4">{`(jasminum officinale)`}</em></h4>
    <p>{`Looks good and grows well with clematis and honeysuckles. A vigorous climber that flowers from mid-summer to autumn. Try ‘Clotted Cream’ for distinctive, larger-than-normal flowers. Likes well-drained soil and plenty of sun.`}</p>
    <h4>{`Star Jasmine `}<em parentName="h4">{`(trachelospermum jasminoides)`}</em></h4>
    <p>{`This half-hardy woody evergreen climber flowers in the summer and has a sweet, intense perfume in the evening. Plant it on a sheltered, sunny side to protect it from hard frost. The dark green leaves turn bronze in winter, for year-round colour interest.`}</p>
    <h4>{`Sweet Peas `}<em parentName="h4">{`(lathyrus odoratus)`}</em></h4>
    <p>{`Famous for their classic fragrance, they also love to climb. Try growing them up a trellis or poles arranged around an upright of your pergola. They also make great cut flowers so you can bring the scent of summer back indoors.`}</p>
    <h4>{`Honeysuckle ‘Graham Thomas’ `}<em parentName="h4">{`(lonicera periclymenum)`}</em></h4>
    <p>{`The large cartwheel-shaped flower-heads open up cream and develop into a deep yellow. It’ll keep on flowering all summer and is attractive to bees and moths. Up to 90cm growth per year. This honeysuckle prefers the shade at its roots but likes to grow into the sun.`}</p>
    <h4>{`Clematis Montana`}</h4>
    <p>{`They may not have large showy flowers but boy can they climb! Flowering in late spring, early summer, it provides a lovely early-season hit of almond-sweet perfume. Like all early-flowering clematis it requires little pruning, apart from the removal of dead and damaged growth after flowering.`}</p>
    <img src="/img/outdoor-living/scented-climbers-for-pergolas/chocolate_vine.jpg" alt="The light pink clover-shaped flowers of a Chocolate Vine (akebia quinata)" />
    <h4>{`Chocolate Vine (akebia quinata)`}</h4>
    <p>{`Not in the usual line-up, this climber bears maroon flowers with an exotic, even spicy, aroma. In hotter climes it produces bizarre sausage-shaped fruit. But we don’t have to worry about that happening in the UK! In mild winters they’ll keep their pretty three-lobed leaves.`}</p>
    <h4>{`Clematis Armandii ‘Snowdrift’`}</h4>
    <p>{`Gorgeous lance-shaped leaves that quickly cover a pergola upright. Flowering in early spring, it has a delicate, fresh fragrance that’s strongest in the evening. Some liken it to the smell of hawthorn blossom. Keep it on a sheltered side of the pergola away from cold winds.`}</p>
    <img src="/img/outdoor-living/scented-climbers-for-pergolas/c-armandii.jpg" alt="The small, white flowers of a Clematis Armandii ‘Snowdrift’" />
    <h4>{`Mandevilla Laxa`}</h4>
    <p>{`Hailing originally from South America, this deciduous climber bears pure white flowers in late summer, and has a beautiful fragrance - another star performer in the evening. Plant it in a really sunny spot, protect it in winter and and it will reward you with its stunning flowers and rich perfume. Not to be missed.`}</p>
    <p>{`After a few years of caring for just one or two of these climbers, your pergola will be clothed all summer in a scented floral backdrop that smells as good as it looks.`}</p>
    <p><strong parentName="p">{`Picture credits:`}</strong></p>
    <ul>
      <li parentName="ul"><small>Sweet Peas - Gardenersworld.com</small></li>
      <li parentName="ul"><small>Star Jasmine - Gardeningexpress.co.uk</small></li>
      <li parentName="ul"><small>Chocolate Vine - Ebay.co.uk</small></li>
      <li parentName="ul"><small>Clematis Armandii ‘Snowdrift’ - Gardeningexpress.co.uk</small></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      